* {
  margin: 0;
  padding: 0;
  
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito Sans", sans-serif;
  font-family: "Roboto", sans-serif;

}
body{

  width: 100%;
  height: 100%;

}
.login-page {
  overflow: hidden;
}
li{
  text-decoration: none;
}
input[type=number]::-webkit-inner-spin-button { 
  -webkit-appearance: none;
  
}
input[type=number] { 
 -moz-appearance: textfield;
 appearance: textfield;

}
.css-wj7f5v-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-wj7f5v-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
  color: #fff;
}
