  * {
    scrollbar-width: auto;
    scrollbar-color: #cccccc #ffffff;
  }

  *::-webkit-scrollbar {
    width: 13px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }